export const ORDER_FEE = 0.5;

// APPLICATION (for api calls to determine correct database)
export const APPLICATION = "frankspizzaoakridge";
// export const APPLICATION = "testfranksoakridge";

// STORE DETAILS
export let HOURS = "";
export let IS_OPEN = "";
export let STORE_NAME = "";
export let STORE_NAME_CODE = "";
export let TAX_PERCENTAGE = 0;
export let DISCOUNT_PERCENTAGE = "";
export let CATEGORY_NAME = "";
export let SUB_CATEGORY_NAME = "";
export const setStore = (
  hours,
  open,
  name,
  nameCode,
  tax,
  discountPercent,
  categoryName,
  subCategoryName,
) => {
  HOURS = hours;
  IS_OPEN = open;
  STORE_NAME = name;
  STORE_NAME_CODE = nameCode;
  TAX_PERCENTAGE = tax;
  DISCOUNT_PERCENTAGE = discountPercent;
  CATEGORY_NAME = categoryName;
  SUB_CATEGORY_NAME = subCategoryName;
};

export const BASE_API = "https://myorderportalservice.com/";
// export const BASE_API = "https://servicefranksoakridge.com/";
export const HOME_REDIRECT = "https://shop.franksoakridge.com/";

// export const BASE_API = "https://localhost:5001/";
// export const HOME_REDIRECT = "http://localhost:3000/";