import React, { useContext, useEffect } from "react";
import ApplicationContext from "../../ApplicationContext";
import { IoIosArrowDown } from "react-icons/io";
import { MdRadioButtonUnchecked } from "react-icons/md";
import { BsCheckCircleFill } from "react-icons/bs";
import { TAX_PERCENTAGE } from "../../constants";

function OrderTipOptions(props) {
  const appContext = useContext(ApplicationContext);
  const tip10Percent = appContext.cart.CartPrice * 0.1;
  const tip15Percent = appContext.cart.CartPrice * 0.15;
  const tip20Percent = appContext.cart.CartPrice * 0.2;
  const tip25Percent = appContext.cart.CartPrice * 0.25;

  function toggleTipOptionsExpandCollapse() {
    if (
      document.getElementById("tipOption_arrow").classList.contains("collapsed")
    ) {
      document.getElementById("tipOption_body").classList.remove("collapse");
      document.getElementById("tipOption_arrow").classList.remove("collapsed");
    } else {
      document.getElementById("tipOption_body").classList.add("collapse");
      document.getElementById("tipOption_arrow").classList.add("collapsed");
    }
  }

  useEffect(() => {
    if (props.tipAmount.type === "Custom") {
      const myInput = document.getElementById("customTip_input");

      const handleInput = (event) => {
        const inputValue = event.target.value;
        props.setTipAmount({
          amount: parseFloat(inputValue),
          type: "Custom",
        });
      };

      myInput.addEventListener("input", handleInput);

      // Cleanup function to remove the event listener when the component unmounts or when the dependency changes
      return () => {
        myInput.removeEventListener("input", handleInput);
      };
    }
  }, [props.tipAmount]);

  function selectTipAmount(amountIn, typeIn) {
    props.setTipAmount({
      amount: parseFloat((Math.round(amountIn * 100) / 100).toFixed(2)),
      type: typeIn,
    });

    var alreadyActive = document.querySelector(".tip_button.Active");
    if (alreadyActive) {
      alreadyActive.classList.remove("Active");
    }

    if (typeIn === "No Tip") {
      var none = document.getElementById("no_tip");
      if (none) {
        none.classList.add("Active");
      }
    } else if (typeIn === "Custom") {
      var custom = document.getElementById("custom_tip");
      if (custom) {
        custom.classList.add("Active");
      }
    } else if (typeIn === "Cash") {
      var cash = document.getElementById("cash_tip");
      if (cash) {
        cash.classList.add("Active");
      }
    } else if (typeIn === "10%") {
      var ten = document.getElementById("ten_tip");
      if (ten) {
        ten.classList.add("Active");
      }
    } else if (typeIn === "15%") {
      var fifteen = document.getElementById("fifteen_tip");
      if (fifteen) {
        fifteen.classList.add("Active");
      }
    } else if (typeIn === "20%") {
      var twenty = document.getElementById("twenty_tip");
      if (twenty) {
        twenty.classList.add("Active");
      }
    } else if (typeIn === "25%") {
      var twentyfive = document.getElementById("twentyfive_tip");
      if (twentyfive) {
        twentyfive.classList.add("Active");
      }
    }
  }

  function setNoTip() {
    props.setTipAmount({
      amount: 0.0,
      type: null,
    });
    selectTipAmount(0.0, "No Tip");
  }

  return (
    <div className="card m-1 tipOption">
      <div
        className="card-header"
        onClick={toggleTipOptionsExpandCollapse}
        data-toggle="collapse-collapsed"
      >
        {props.tipAmount.amount ? (
          <div className="tipOption_choice">
            {props.orderType === "Delivery" ? (
              <BsCheckCircleFill className="checked" />
            ) : null}
            {props.tipAmount.type !== "No Tip" ? (
              <div className="tipOption_header">
                {props.tipAmount.type + " Tip - $" + props.tipAmount.amount}
              </div>
            ) : (
              <div className="tipOption_header">
                {props.tipAmount.type + " - $" + props.tipAmount.amount}
              </div>
            )}
          </div>
        ) : (
          <div className="tipOption_choice">
            {props.orderType === "Delivery" ? (
              <MdRadioButtonUnchecked className="checkbox" />
            ) : null}
            <div className="tipOption_header">Tip</div>
          </div>
        )}
        <IoIosArrowDown id="tipOption_arrow" />
      </div>
      <div id="tipOption_body" className="card-body">
        <div className="tipOption_container">
          <div className="tip_options">
            <div className="tip_options">
              <div className="buttons_tip">
                <div className="tips other_tips">
                  {props.orderType === "Pickup" && (
                    <div className="tip_button" id="no_tip" onClick={setNoTip}>
                      No Tip
                    </div>
                  )}
                  <div
                    className="tip_button"
                    id="custom_tip"
                    onClick={() => selectTipAmount(0.0, "Custom")}
                  >
                    Custom
                  </div>
                  <div
                    className="tip_button"
                    id="cash_tip"
                    onClick={() => selectTipAmount(0.0, "Cash")}
                  >
                    Cash
                  </div>
                </div>
                <div className="tips">
                  <div
                    className="tip_button"
                    id="ten_tip"
                    onClick={() => selectTipAmount(tip10Percent, "10%")}
                  >
                    10% ({"$" + tip10Percent.toFixed(2)})
                  </div>
                  <div
                    className="tip_button"
                    id="fifteen_tip"
                    onClick={() => selectTipAmount(tip15Percent, "15%")}
                  >
                    15% ({"$" + tip15Percent.toFixed(2)})
                  </div>
                  <div
                    className="tip_button"
                    id="twenty_tip"
                    onClick={() => selectTipAmount(tip20Percent, "20%")}
                  >
                    20% ({"$" + tip20Percent.toFixed(2)})
                  </div>
                  <div
                    className="tip_button"
                    id="twentyfive_tip"
                    onClick={() => selectTipAmount(tip25Percent, "25%")}
                  >
                    25% ({"$" + tip25Percent.toFixed(2)})
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          {props.tipAmount.type === "Custom" ? (
            <div className="tip_customAmount">
              <div>Custom Amount: </div>
              <input
                id="customTip_input"
                className="customTip_input"
                placeholder="Enter a Tip"
                type="number"
                min="0"
              />
            </div>
          ) : null}

          <div className="tip_info">100% of tips go directly to the store!</div>
        </div>
      </div>
    </div>
  );
}

export default OrderTipOptions;
